@keyframes flash {
  0% {
    opacity: 0;
  }
  34% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  79% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  82% {
    opacity: 0;
  }
  83% {
    opacity: 1;
  }
  84% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  91% {
    opacity: 0
  }
  95% {
    opacity: 1;
  }
  100% {
    opactiy: 0;
  }
}
@keyframes flash-2 {
  0% {
    opacity: 0;
  }
  29% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  79% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes glitch-1 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i * (1/$steps))} {
      $top: random(100);
      $bottom: 95 - $top;
      
      clip-path: inset(
        #{$top}%
        0
        #{$bottom}%
        0
      );
    }
  }
}
@keyframes glitch-2 {
  $steps: 6;
  $colors: $red, $ltblue, $dkblue;
  $colorcount: 1;
  
  @for $i from 0 through $steps {
    #{percentage($i * (1/$steps))} {
      @if ($i % 2 == 0) {
        $top: $i * 10;
        $bottom: random(105 - $top);
        $color: nth($colors, $colorcount);
        
        clip-path: inset(#{$top}% 0 #{$bottom}% 0);
        left: #{$i}px;
        
        @include text-border(#{$color});
      
        @if($colorcount == $steps/2) {
          $colorcount: 1;
        } @else {
          $colorcount: $colorcount + 1;
        }
      }
    }
  }
}