#welcome-section, #intro {
  &, > .container {
    height: 100vh;
    overflow: hidden;
  }
  
  > .container {
    position: relative;
  }
}

#intro--title {
  h6 {
    position: relative;
    top: 15vh;
    left: -4.6rem;
    width: 180vw;
    margin: 0 0 12vh 0;
    color: $dkrblue;
    font-size: 12rem;
    font-weight: 800;
    line-height: 0.65;
    @include text-border($red);
    z-index: 1;


    &::after {
      display: none;
      content: attr(data-text);
      position: absolute;
      left: 2px;
      top: 2px;
      clip-path: inset(15% 0% 80% 0%);
      @include text-border($red);
      animation: glitch-1 5s infinite linear alternate, flash-2 1s infinite ease-out alternate;
    }
    &::before {
      display: none;
      content: attr(data-text);
      position: absolute;
      left: -12px;
      top: -2px;
      clip-path: inset(40% 0% 25% 5%);
      @include text-border($red);
      animation: glitch-2 6s infinite linear alternate, flash-2 1.2s infinite ease-out alternate;
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 3vw;
      width: 75vw;
      transform: translateY(-50%);
      margin: 0;
      font-size: 29vw;
      
      &::after, &::before {
        display: block;
      }
    }
  }

  .intro-subtitles {
    position: relative;
    max-width: 60%;
    margin: 20vh -3vw 0 auto;
    z-index: 2;

    @media (min-width: 480px) and (max-width: 767px) {
      max-width: 32%;
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 59%;
      height: 50vh;
      width: 35vw;
      transform: translateY(-4.5rem);
      margin: 0;
    }
  }

  h1, h2 {
    position: relative;
    font-size: 2rem;
    font-weight: 400;
    color: $white;
    letter-spacing: -1px;

    @media (min-width: 768px) and (max-width: 1023px) {
      // font-size: 3rem;  
    }

    @media (min-width: 1024px) {
      font-size: 3.5vw;
    }
  }

  h1 {
    margin-bottom: 4rem;

    @media (min-width: 1024px) {
      margin-bottom: 6.3rem;
    }
  }

  h2 {
    @media (min-width: 1024px) {
      font-size: 4vw;
    }
  }
}

.tool {
  position: absolute;
  left: 0;
  bottom: -2.5rem;
  opacity: 0;
  filter: blur(25px);
  color: $red;
  animation-name: opacity-blur-fade;
  animation-timing-function: ease-in;
  animation-duration: 9s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: normal;
  @if($env == 'dev') {
    animation-play-state: paused;

    &:nth-child(1) {
      opacity: 1;
      filter: blur(0);
    }
  } else {
    animation-play-state: running;
  }

  &:nth-child(2) {
    animation-delay: 3s;
  }

  &:nth-child(3) {
    animation-delay: 6s;
  }

  @media (min-width: 768px) {
    bottom: -3.5rem;
  }
  
  @media (min-width: 1024px) {
    bottom: 0;
    left: 18vw;
  }
}