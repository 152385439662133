// SETTING ENVIRNOMENT VARIABLE FOR LATER USE
$env: 'production';

@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@400;500;600;700;800&display=swap');

@mixin text-border($borderColor) {
  text-shadow:  1px 1px 0px #{$borderColor},  1px -1px 0px #{$borderColor},
                -1px 1px 0px #{$borderColor}, -1px -1px 0px #{$borderColor},
                2px 2px 0px #{$borderColor},  2px -2px 0px #{$borderColor},
                -2px 2px 0px #{$borderColor}, -2px -2px 0px #{$borderColor};  
}

@mixin text-border-light($borderColor) {
  text-shadow:  1px 1px 0px #{$borderColor},  1px -1px 0px #{$borderColor},
                -1px 1px 0px #{$borderColor}, -1px -1px 0px #{$borderColor};  
}

$white: #E0E0E0;
$red: #CC3E37;
$ltblue: #227CCC;
$nvblue: #17558D;
$blue: #144D7F;
$dkblue: #113F68;
$dkrblue: #071E32;

$padding-default: 15px;

@keyframes opacity-blur-fade {
  50%, 60% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes bounce-y {
  50%, 55% {
    transform: translateY(-25%);
  }
}