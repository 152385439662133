#about {
  background-color: $white;

  .row {
    flex-direction: column;
  }

  .module-body-list {
    @media (max-width: 767px) {
      columns: 2;
    }
    
    @media (min-width: 768px) {
      columns: 4;
    }
  }

  a {
    &, &:hover, &:focus {
      text-decoration: underline;
      color: $white;
      background-color: $ltblue;
      padding: 0.25rem;
    }
  }
}

