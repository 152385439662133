#projects {
  background-color: $blue;
  color: $white;
  padding-bottom: 4rem;
  
  > .container {
    padding: 0 4rem;
  }

  &.module {
    .module-header {
      
      @media (max-width: 767px) { 
        transform: translateX(-24vw);
      }
      
      @media (min-width: 768px) { 
        transform: translateX(-4rem);
      }

      .module-header-text {
        color: $blue;
        @include text-border-light($white);
      }
    }
    
    .module-body {
      p {
        color: $white;
        font-weight: 600;
        line-height: 1.25;
        margin-bottom: 4rem;
      }
    }
  }

  figure {
    @media (max-width: 767px) {
      margin-bottom: 2rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .project-title-text {
    font-size: 4rem;
    font-weight: 900;
    line-height: 0.75;
    margin: 0 0 4rem;
    word-break: break-all;
  }

  .project-title-text.mobile {
    font-size: 3.5rem;
    margin: 4rem 0 4rem;
  }

  .project {
    position: relative;
    min-height: 30vh;
    margin-bottom: 15vh;
    
    &.row {
      align-items: flex-end;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 110%;
      height: 110%;
      background-color: $red;
      border: lighten($red, 10%) 2px solid;
      opacity: 0.35;
      transform: skew(-10deg, -10deg) translateX(-20px);
      pointer-events: none;

      @media (min-width: 768px) {
        height: 70%;
        transform: skew(-10deg, -10deg) translate(3%, 58%);
      }
 
      @media (min-width: 1024px) {
        height: 90%;
        transform: skew(-10deg, -10deg) translate(3%, 18%);
      }
    }
  }
}