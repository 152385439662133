#site-header {
  position: fixed;
  background-color: transparent;
  width: 100vw;
  z-index: 10;
  
  .container {
    padding: 15px;
  }

  #logo {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 12;

    img {
      max-width: 6rem;
      height: auto;
      z-index: 13;
    }
  }

  #menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    height: 100vh;
    background-color: $dkrblue;
    padding: 6.5rem 4rem;
    z-index: 11;
    overflow: hidden;
    transition: transform 650ms ease-out;

    &.closed {
      transform: translateX(-100vw);
    }
    
    &.open {
      transform: translateX(0vw);
    }

    .row {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-direction: column;
    }

    #site-title, #site-subtitle {
      font-weight: 700;
    }
    
    #site-title {
      display: inline;
      font-size: 3rem;
      color: $red;
    }
    
    #site-subtitle {
      font-size: 1.35rem;
    }

    #site-nav {
      margin: 2rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .nav-link {
        font-size: 3rem;
        font-weight: 700;
        line-height: 2;
      }
    }
  }

}