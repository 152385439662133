html {
  font-size: 12px;
  width: 100vw;
  overflow-x: hidden;
  scrollbar-color: $red transparent;
  scrollbar-width: thin;
  
  @media (min-width: 375px) { 
    font-size: 14px;
  }

  @media (min-width: 1024px) { 
    font-size: 16px;
  }


  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none;
    mix-blend-mode: difference;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $red;
    outline: none;
    border-radius: 1rem;
  }
}

body {
  font-family: 'Blinker', sans-serif;
  font-weight: 400;
  background-color: $dkrblue;
  color: $white;
  margin: 0;
  padding: 0;
  width: 100%;

  * {
    box-sizing: border-box;
  }
}

.module {
  display: block;
  overflow-x: hidden;

  .module-header {
    background-color: transparent;

    @media (max-width: 767px) {
      transform: translateX(-15vw);
    }

    @media (min-width: 768px) {
      padding-top: 10vh;
    }

    .module-header-text {
      font-size: 50vw;
      font-weight: 600;
      letter-spacing: -0.50rem;
      @include text-border-light($ltblue);

      @media (min-width: 768px) {
        font-size: 25.5vw;
      }
    }
  }

  .module-body {
    .module-subheader, p, ul, ol {
      color: $dkrblue;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .module-subheader {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
    }

    @media (min-width: 768px) {
      transform: translateY(-11vw);
    }
  }
}

.container {
  max-width: 100%;
  padding: 0 15px;
}

.row {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.col {
  flex: 1 0 100%;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 1vw;
    max-width: 50vw;

    &.w-1 {
      max-width: 10vw;
    }
    &.w-2 {
      max-width: 23vw;
    }
    &.w-3 {
      max-width: 30vw;
    }
    &.w-4 {
      max-width: 40vw;
    }
    &.w-5 {
      max-width: 50vw;
    }
    &.w-6 {
      max-width: 60vw;
    }
    &.w-7 {
      max-width: 70vw;
    }
    &.w-8 {
      max-width: 80vw;
    }
    &.w-9 {
      max-width: 90vw;
    }
    &.w-10 {
      max-width: 100vw;
    }
    &.p-1 {
      margin-left: 8vw;
    }
    &.p-2 {
      margin-left: 16vw;
    }
    &.p-3 {
      margin-left: 24vw;
    }
    &.p-4 {
      margin-left: 32vw;
    }
    &.p-45 {
      margin-left: 38vw;
    }
    &.p-5 {
      margin-left: 40vw;
    }
    &.p-6 {
      margin-left: 48vw;
    }
    &.p-7 {
      margin-left: 56vw;
    }
    &.p-8 {
      margin-left: 64vw;
    }
    &.p-9 {
      margin-left: 72vw;
    }
    &.p-10 {
      margin-left: 80vw;
    }
    &.center {
      margin: 0 auto;
    }
  }
  @media (min-width: 1024px) {
    padding: 0 2vw;
  }
}

.down-arrow {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50vw;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);

  @media (min-width: 992px) {
    display: block;
  }
}

.down-arrow-button {
  background-color: transparent;
  position: relative;
  appearance: none;
  border: none;
  color: $white;
  width: 100%;
  height: 100%;
  font-size: 0.5rem;
  cursor: pointer;


  &::after, &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -70%) rotate(45deg);
  }

  &::before{
    margin-top: 2px;
    background-color: $white;
  }

  &::after{
    background-color: $dkrblue;
  }

  &:focus {
    border: none;
    outline: none;
  }
}

.text-red {
  color: $red!important;
}

.list-styled {
  list-style-type: square;
  list-style-position: inside;
}

a {
  &, &:hover, &:focus {
    text-decoration: none;
    color: inherit;
  }
}

button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
}

.btn, button {
  appearance: none;
  text-align: center;
  font-weight: 900;
  font-size: 1.2rem;

  &.btn-clear {
    display: block;
    background-color: transparent;
    border-color: $white;
    border-width: 2px;
    border-style: solid;
    color: $white;
    text-decoration: none;
    padding: 1rem 2rem;
  }
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }  
}
@media (max-width: 767px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}