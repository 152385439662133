#contact {
  background-color: $dkblue;
  color: $white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
  border-bottom: calc(1rem * 0.5) solid $red;

  .row {
    align-items: flex-end;
  }

  .col {
    margin-bottom: 4rem;
    
    .header-text {
      font-size: 3rem;
      font-weight: 600;
      line-height: 1.2;
      margin: 4rem 0;
    }
    @media (min-width: 768px) {
      &:nth-child(2) .header-text {
        margin-bottom: 1rem;
      }
    }
  }


  .btn {
    border-width: 3px;
    font-size: 2rem;
    font-weight: 700;
    padding: 2rem 0;
  }

  a {
    background-color: transparent;
  }

  .social-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3rem;
    
    li {
      flex: 1 0 20%;
      max-width: 20%;

      a {
        display: block;

        img {
          min-width: 100%;
          height: auto;
        }
      }
    }
  }
}